
.collaborate-header {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'Pacifico';
    color: #9a0808;
    text-align: center;
    margin-top: 20px;
    font-size: 3.5rem;
}

.collaborate-header::after {
    content: '';
    border-top: 5px solid #9a0808;
    margin-top: 2%;
    width: 10%;
    margin-left: 45%;
}

.collaborate-content-wrapper {
	padding-left: 17.5%;
    padding-right: 17.5%;
    margin-bottom: 5%;
    background-color: white;
}


.collaborate-text-wrapper {
    display: flex;
    flex-direction: column;
	padding-left: 17.5%;
    padding-right: 17.5%;
}

.about-us-text-wrapper {
    display: flex;
    flex-direction: column;
}

.about-us-text-wrapper p {
    column-count: 2;
    column-gap: 3vw;
}

.about-us-text-wrapper p:nth-child(1)::first-letter {
    font-size: 2.5em;
    color: #9a0808;
    margin-left: 20px;
    margin-right: 8px;
    line-height: 8px;
    font-family: 'Pacifico';
}

.about-us-text-wrapper p:nth-child(3)::first-letter {
    font-size: 2.5em;
    color: #9a0808;
    margin-left: 20px;
    margin-right: 8px;
    line-height: 8px;
    font-family: 'Pacifico';
}

.about-us-text-wrapper b {
    color: #9a0808;
}

.collaborate-text-wrapper p:nth-child(1)::first-letter {
    font-size: 2.5em;
    color: #9a0808;
    margin-left: 20px;
    margin-right: 8px;
    line-height: 8px;
    font-family: 'Pacifico';
}

.collaborate-text-wrapper b {
	color: #9a0808;
}

.collaborate-types-wrapper {
	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
	justify-content: space-around;
	padding-left: 15.5%;
    padding-right: 15.5%;
}

.collaborate-types-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 26%;
	padding: 2%;
	text-align: center;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 5%;
    max-width: 375px;
    min-width: 300px;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
	border-radius: 10em/1em;
	cursor: pointer;
	transition: 0.3s;
	border-top: 5px solid #9a0808;
	border-bottom: 5px solid #9a0808;
}

.collaborate-types-card:hover {
	box-shadow: 0 4px 20px 0 rgba(0,0,0,0.25);
	transform: translateY(-5px);
}

.collaborate-volunteer h2 {
	display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'Pacifico';
    font-weight: 400;
    color: #9a0808;
}

.collaborate-volunteer h2::after {
    content: '';
    border-top: 3px solid #9a0808;
    margin-top: 2%;
    width: 20%;
    margin-left: 40%;
}

.collaborate-volunteer p {
    padding-left: 2%;
    padding-right: 2%;
 }

.styled-button-white-bg{
    cursor: pointer;
    width: 10%;
    visibility: visible; 
    color: transparent;
    display: inline-block;
    font-size: calc(14px + 0.5vw);
    font-family: 'Pacifico';
    width: auto;
    height: calc(55px + 0.5vw);;
    padding: 12px;
    color: #9a0808;
    border: 2px solid #9a0808;
    border-radius: 5px;
    background-color: transparent;
    text-align: center;
    outline: none;
    text-decoration: none;
}

.styled-button-white-bg:hover {
    -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    background-color: #9a0808;
    color: #fff;
}

.about-us-vision-wrapper, .about-us-mission-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.about-us-vision-img {
    max-width: 300px;
    height: auto;
}

.mission-list {
    list-style: none;
    padding-left: 0;
}

.mission-list li {
    margin: 0;
	margin-bottom: 20px;
	padding-left: 1.5em;
	position: relative;
}

.mission-list li b {
	color: #9a0808;
}

.mission-list li:before {
	content: '';
	height: .4em;
	width: .4em;
	background: #9a0808;
	display: block;
	position: absolute;
	transform: rotate(45deg);
	top: .55em;
	left: 0;
}

.donate-modal-wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.donate-modal-wrapper img{
	width: 40%;
    height: auto;
    margin-bottom: 0px;
}

.donate-modal-main h2 {
	font-family: "Pacifico";
	font-weight: 400;
    font-size: 2.5em;
    line-height: 50px;
	color: #9a0808;
	display: flex;
	flex-direction: column;
}

.donate-modal-main h2::after {
    content: '';
    border-top: 5px solid #9a0808;
    margin-top: 20px;
    width: 20%;
    margin-left: 40%;
}

.donate-modal-main {
	background-color: white;
	display: flex;
    flex-direction: column;
    position: relative;
	padding: 0% 5% 0% 5%;
	justify-content: space-between;
	text-align: center;
	width: 55%;
}

.donate-modal-close {
    color: white;
    background-color: #9a0808;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 10;
}

@media screen and (max-width: 1600px) {
    .collaborate-types-wrapper {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
}


@media screen and (max-width: 1200px) {

    .collaborate-content-wrapper {
        padding-left: 5%;
        padding-right: 5%;
    }

    .collaborate-header {
        margin-top: 20px;
        font-size: 3rem;
    }


    .collaborate-text-wrapper {
        padding-left: 5%;
        padding-right: 5%;
    }

    .about-us-text-wrapper {
        padding-left: 5%;
        padding-right: 5%;
    }

    .about-us-vision-wrapper, .about-us-mission-wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {

    .collaborate-types-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .collaborate-types-card {
        display: flex;
        flex-direction: column;
        width: 80%;
        padding: 5%;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .about-us-text-wrapper p {
        column-count: 1;
    }
    
    .donate-modal-wrapper {
        flex-direction: column;
    }

    .donate-modal-wrapper img {
        width: 90%;
        margin-bottom: 50px;
        max-width: 400px;
    }

    .donate-modal-main {
        width: 95%;
    }

    .donate-modal-main h2 {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .collaborate-types-card {
        min-width: 100px;
        margin-left: 0;
        margin-right: 0;
    }
    .collaborate-types-wrapper {
        padding: 0;
    }
}
