.testimonial-main {
	background-color: white;
	padding-left: 7.5%;
	padding-right: 7.5%;	
	padding-top: 7px;
	overflow: hidden;
	margin-top: 50px;
	margin-bottom: 20px;
}


.testimonial-header h2{
	display: flex;
	flex-direction: column;
}

.testimonial-header h2::after {
	content: '';
	border-top: 2px solid black;
	margin-top: 0.5em;
	margin-left: auto;
	margin-right: auto;
	width: 15%;
}

.testimonial-card-wrapper-outer {
	overflow: visible;
	position: relative;
	width: 100%;
	height: auto;
}

.testimonial-card-wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	width: 600vw;
	height: auto;
	transition: transform 1s;
	/*animation: translatetestimonials 65s ease infinite;
	animation-fill-mode: forwards;*/
}

@keyframes translatetestimonials {
	0%, 15.3% {
		transform: translateX(0%);
	}
	15.3%, 16.3% {
		transform: translateX(-16.66%);
	}
	16.3%, 31.6% {
		transform: translateX(-16.66%);
	}
	31.6%, 32.6% {
		transform: translateX(-33.33%);
	}
	47.9%, 57.9% {
		transform: translateX(-49.99%);
	}
	73.2%, 83.2% {
		transform: translateX(-66.65%);
	}
	98.5%, 99.5% {
		transform: translateX(-83.31%);
	}
	100% {
		transform: translateX(0px);
	}
}

.left-arrow, .right-arrow {
	position: relative;
	width: 50px;
	height: 50px;
	cursor: pointer;
	background-color: #9a0808;
	border-radius: 50%;
	padding: 11px 17px 11px 17px;
}

.left-arrow {
	float: left;
	margin-right: 20px;
}

.right-arrow {
	float: right;
	margin-left: 20px;
	transform: rotate(180deg);
}

.testimonial-card {
	display: flex;
	background-color: white;
	border-radius: 5px;
	border-left: 5px solid #9a0808;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
	margin: 35px auto 1% auto;
	justify-content: center;
	width: 95%;
	overflow: hidden;
	position: relative;
}

.testimonial-card:after{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 25px solid #9a0808;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(-45deg);
    transform-origin: 80% -30% 0;
}

.testimonial-pic {
	width: 20%;
	height: auto;
	text-align: center;  /* align the inline(-block) elements horizontally */
    font: 0/0 a;         /* remove the gap between inline(-block) elements */
}

.testimonial-pic:before {
	content: '';
    display: inline-block;
    vertical-align: middle;  /* vertical alignment of the inline element */
    height: 100%;
    width: 10%;
}

.pic {
	display: inline-block;
    vertical-align: middle;  /* vertical alignment of the inline element */
	height: auto;
	width: 60%;
	border-radius: 50%;
}

.testimonial-content {
	width: 80%;
	quotes: '\201c' '\201d';
	justify-content: center;
}

.testimonial-content:before {
	color: #9a0808;
	content: open-quote;
	font-size: 4em;
}

.main-content {
	width: 90%;
	margin: -35px 0px 35px 20px;
}


.main-content h3 {
	margin-top: 20px;
	color: #9a0808;
}

.ellipses {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	width: 100%;
	height: auto;
	margin-top: 0px;

}

.page {
	border: 2px solid #9a0808;
	cursor: pointer;
	height: 10px;
	width: 10px;
	margin-left: 2%;
	margin-right: 2%;
	margin-top: 20px;
	border-radius: 50%;
}

.active-1 {
	background: #9a0808;
}

.active0 {
	transform: translateX(0%);
}

.active1 {
	transform: translateX(-16.66%);
}

.active2 {
	transform: translateX(-33.33%);
}

.active3 {
	transform: translateX(-49.99%);
}

.active4{
	transform: translateX(-66.65%);
}

.active5{
	transform: translateX(-83.31%);
}


@media (max-width: 992px) {
}

@media (max-width: 768px) {

	.testimonial-card-wrapper {
		width: 1200%;
		align-items: flex-start;
	}

	.testimonial-card {
		flex-direction: column;
		align-items: center;
		margin: 35px 8.5% 1% 0%;
		width: 95%;
		border-radius: 0%;
	}

	.testimonial-pic {
		width: 50%;
		margin-top: 5%;
		justify-content: center;
	}

	.testimonial-pic:before {
		display: inline;
	    width: 0%;
	}

	.testimonial-content {
		width: 90%;
	}

	.main-content {
		width: 90%;
	}

	.testimonial-header h2::after {
		width: 25%;
	}

	.ellipses {
		align-content: space-between;
		justify-content: space-between;
	}

	.page {
		margin-left: 3%;
		margin-right: 3%;
		height: 10px;
		width: 10px;
	}
}

@media (max-width: 400px) { 
	.left-arrow {
		margin-right: 0px;
	}

	.right-arrow {
		margin-left: 0px;
	}
}
