.details-all {
	background-color: white;
	height: auto;
	overflow: hidden;
}

.flower-projects-mid-screen {
	display: none;
}

.details-large-screen-wrapper {
	display: none;
}

.details-small-screen-wrapper {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-start;
}

.details-left-sm {
	width: auto;
}

.details-right-sm {
	width: auto;
	margin-top: -3rem;
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 576px) {  

}

@media (min-width: 620px) {  
	.details-small-screen-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
	}
	.details-left-sm {
		align-self: right;
	}
	.details-right-sm {
		align-self: left;
		margin-top: initial;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  


}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
	.details-small-screen-wrapper {
		display: none;
	}

	.details-large-screen-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: flex-start;
		width: 100%;
		height: auto;
		margin-top: 50px;
		margin-bottom: 50px;
	}

	.flower-projects {
		display: none;
	}

	.flower-projects-mid-screen {
		display: flex;
		margin-left: 30%;
		margin-right: 30%;
		transition:all .2s ease-in-out;
		-webkit-filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.50) );
		filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.50));
	}

	.flower-projects-mid-screen:hover {
		transform: translateY(-7px);
		-webkit-filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.70) );
		filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.70));
	}


	.details-left {
		width: auto;
		padding-right: 2vw;
		padding-left: 2vw;
		margin-top: -10vw;
	}

	.details-left-left {
		position: relative;
		left: 0vw;
		margin-top: 3vw;
		margin-bottom: 3vw;
	}

	.details-right {
		width: auto;
		padding-left: 2vw;
		padding-right: 2vw;
		margin-top: -10vw;
	}

	.details-right-right {
		position: relative;
		right: 0vw;
		margin-top: 3vw;
		margin-bottom: 3vw;
	}

	.details-center {
		width: auto;
		align-self: normal;
		padding-left: 2vw;
		padding-right: 2vw;
		margin-top: 10vw;
		display: flex;
		flex-direction: column;
	}

	.details-top {
		width: auto;
	}

	.details-bottom {
		width: auto;
		margin-bottom: 10%;
	}

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  

	.flower-projects-mid-screen {
		display: none;
	}

	.flower-projects {
		display: block;
		width: auto;
		max-width: 700px;
		height: auto;
		margin-top: 4vw;
		margin-bottom: 4vw;
		margin-right: 0;
		margin-left: 0;
		align-self: center;
		transition:all .2s ease-in-out;
		-webkit-filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.50) );
		filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.50));
	}

	.flower-projects:hover {
		transform: translateY(-7px);
		-webkit-filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.70) );
		filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.70));
	}

	.details-top {
		align-self: center;
	}

	.details-bottom {
		align-self: center;
	}

	.details-left {
		margin-top: 2vw;
	}

	.details-center {
		margin-top: 0px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.details-left-left {
		left: 5vw;
		margin-top: 6vw;
		margin-bottom: 6vw;
	}

	.details-right-right { 
		right: 5vw;
		margin-top: 6vw;
		margin-bottom: 6vw;
	}

	.details-right {
		margin-top: 2vw;
	}

}


@media (min-width: 1300px) {

}

@media (min-width: 1400px) {
	.flower-projects {
		width: 525px;
	}
}

@media (min-width: 1600px) {
	.flower-projects {
		width: auto;
	}
}

@media (min-width: 1700px) {
    
}

