.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
 	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.car-ind-sm li {
	background-color: black;
}

.car-ind-lg li {
	background-color: black;
}

.slider-left-wrapper {
	height: auto;
	margin-top: 1.5rem;
}

.slider-long-left-wrapper {
	height: auto;
	margin-top: 3rem;
}

.custom-slider-img {
	height: 50vw;
}

.custom-slider-img, .custom-slider-long-img {
	border-radius: 8px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 10px solid white;
	-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.2); 
	box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
	overflow: hidden;
}

.car-ind-sm {
	top: 55vw;
}

.car-ind-lg {
	bottom: -45px;
}

.carousel-control-prev {
	left: -15px;
}

.carousel-control-next {
	right: -15px;
}



/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
	.slider-left-wrapper {
		width: 50%;
	}

	.slider-long-left-wrapper {
		height: auto;
		margin-top: 0rem;
		width: 400px;
	}

	.custom-slider-img {
		height: 28vw;
	}

	.car-ind-sm {
		top: 31vw;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 860px) {  
	.slider-left-wrapper {
		margin-top: 0;
	}
	.slider-long-left-wrapper {
		width: 50%;
	}
}


/* Medium devices (tablets, 992px and up) */
@media (min-width: 992px) {
	.slider-left-wrapper {
		width: 45%;
	}

	.slider-long-left-wrapper {
		width: 50%;
	}

	.custom-slider-img {
		height: 23vw;
	}

	.car-ind-sm {
		top: 25vw;
	}
}

/* Medium devices (tablets, 992px and up) */
@media (min-width: 1200px) {
	.custom-slider-img {
		height: 275px;
	}
	.car-ind-sm {
		top: 300px;
	}

}

/* Medium devices (tablets, 992px and up) */
@media (min-width: 1500px) {
	.custom-slider-img {
		height: 320px;
	}
	.car-ind-sm {
		top: 350px;
	}
	.slider-long-left-wrapper {
		width: 35%;
		margin-right: 2.5%;
		margin-left: 5%;
	}
}