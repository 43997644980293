.content-ekadashi-left-slider-wrapper {
	overflow: hidden;
}

.content-ekadashi-left-slider-wrapper-display {
	display: flex;
	flex-direction: column-reverse;
}

.content-ekadashi-right-slider-wrapper-display {
	display: flex;
	flex-direction: column;
}

.greybox-div-ekadashi {
	background-color: white;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.greybox-div-ekadashi h2 {
	display: flex;
	flex-direction: column;
	text-align: left;
}


.greybox-div-ekadashi h2::after {
	content: '';
	border-top: 2px solid black;
	margin-top: 1em;
	width: 20%;
}

.greybox-div-ekadashi p {
	text-align: justify;
}

.greybox-div-ekadashi p b {
	color: #9a0808;
}

.slider-long-extra-text-display-medium-sc {
	display: none;
}

.slider-long-extra-text {
	display: contents;
	text-align: justify;
}





.eleven-benefits {
	background-image: url("https://satprerna.org/satprerna_backend/images/ekadashi/eleven-benefits_#9a0808.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position: 8% top;
	height: auto;
}

.benefits-container {
	height: auto;
	margin-left: 7.5%;
	margin-right: 7.5%;
	padding-left: 7.5%;
	padding-right: 7.5%;
	color: black;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
}


.benefits-container h2 {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.benefits-container h2::after {
	content: '';
	border-top: 2px solid black;
	margin-top: 0.8em;
	width: 20%;
}

.benefits-container ul {
	list-style: none;
}

.benefits-container  ul li {
	margin: 0;
	margin-bottom: 1em;
	padding-left: 1.5em;
	position: relative;
  }

.benefits-container ul li:before {
	content: '';
	height: .4em;
	width: .4em;
	background: #9a0808;
	display: block;
	position: absolute;
	transform: rotate(45deg);
	top: .55em;
	left: 0;
  }
  

.benefits-container-unhovered {
	animation: benefits-container-animate-reverse 0.2s ease-out forwards;
}

.benefits-container:hover {
	animation: benefits-container-animate 0.2s ease-in-out forwards;
}

@keyframes benefits-container-animate {
	0% {
		background-color: rgba(256, 256, 256, 0.9);
	}
	100% {
		background-color: rgba(256, 256, 256, 1);
	}
}

@keyframes benefits-container-animate-reverse {
	0% {
		background-color: rgba(256, 256, 256, 1);
	}
	100% {
		background-color: rgba(256, 256, 256, 0.9);
	}
}


.ekadashi-types-main {
	background-color: white;
	overflow: hidden;
}

.ekadashi-types-header {
	display: flex;
	flex-direction: column;
}

.ekadashi-types-header::after {
	content: '';
	border-top: 2px solid black;
	margin-top: 0.8em;
	width: 10%;
	margin-left: 45%;
}

.ekadashi-types-card-wrapper-outer {
	overflow: hidden;
	position: relative;
	height: auto;
}

.ekadashi-types-card-wrapper {
	display: flex;
	flex-flow: row wrap; /* Shorthand – you could use ‘flex-direction: column’ and ‘flex-wrap: wrap’ instead */
	justify-content: center;
	align-items: flex-start;
	height: auto;
	transition: transform 1s;
	overflow-y: hidden;
	/*animation: translateTestimonials 65s ease infinite;
	animation-fill-mode: forwards;*/
}



/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) {  
	.content-ekadashi-left-slider-wrapper {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
	.slider-long-extra-text {
		display: none;
	}

	.slider-long-extra-text-display-medium-sc {
		display: inherit;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.slider-long-extra-text-display-medium-sc b {
		color: #9a0808;
	}
	.greybox-div-ekadashi {
		width: 50%;
	}

	.content-ekadashi-left-slider-wrapper {
		display: flex;
		flex-direction: row;
	}

	.content-ekadashi-right-slider-wrapper {
		display: flex;
		flex-direction: row;
	}

	.greybox-div-right-carousel-ekadashi {
		width: 56.5vw;
		margin-right: 1rem;
		margin-left: 1rem;
	}
}


/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
	.slider-long-extra-text {
		display: contents;
	}

	.slider-long-extra-text-display-medium-sc {
		display: none;
	}
	
	.greybox-div-ekadashi {
		width: 55%;
	}

	.content-ekadashi-left-slider-wrapper {
		align-items: center;
	}

	.greybox-div-right-carousel-ekadashi {
		width: calc(92vw - 400px);
		margin-left: 3rem;
		margin-right: 1.5rem;
	}

	.benefits-container {
		margin-left: 25%;
	}


	.ekadashi-types-subheader {
		width: 900px;
	}
}

@media (min-width: 1200px) {
    .content-ekadashi-left-slider-wrapper {
		margin-left: 7.5%;
		margin-right: 7.5%;
	}

	.benefits-container {
		margin-left: 37.5%;
	}

	.ekadashi-types-subheader {
		width: 1100px;
	}
}

@media (min-width: 1400px) {

	.greybox-div-right-carousel-ekadashi {
		width: calc(77vw - 400px);
		margin-left: 10%;
		margin-right: 1rem;
	}
}