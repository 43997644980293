.pmt-cover-img-links{
	cursor: pointer;
	color: black;
	margin: 5px 8px 5px 8px;
	font-size: 0.9rem;
	font-weight: 500;
	font-family: 'lato';
    text-decoration: none;
    outline: 0;
    background-color: transparent;
    border: none;
	transition: 0.2s ease-in-out;
}

.pmt-cover-img-links:hover{
    color: #ff7700;
    border: none;
    outline: 0;
}

.pmt-cover-img-links:focus{
    color: #ff7700;
    border: none;
    outline: 0;
}

.pmt-cover-img-links:active{
    color: #ff7700;
    border: none;
    outline: 0;
}