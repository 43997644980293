
.three-i-principle {
	background-image: url("https://satprerna.org/satprerna_backend/images/ascentoflife/ascent2.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position: center;
	height: auto;
	overflow: hidden;
	color: white;
}

.three-i-principle-header {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.three-i-principle-header::after {
	width: 20%;
	content: '';
	border-top: 2px solid white;
	margin-top: 0.5em;
	margin-left: 40%;
}

.three-i-principle-content-wrapper {
	position: relative;
	height: auto;
	display: flex;
	flex-direction: row;
}

.three-i-principle-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}


.three-i-principle-content-point {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}
/*
.three-i-principle-content::before {
	position: absolute;
	content: '';
	border-left: dashed 2px rgba(255,255,255,0.3);
	height: 80%;
	left: 30px;
}
*/
.three-i-principle-icon img {
	background-color: rgba(255,255,255,0.1);
	filter: brightness(0) invert(1);
	padding: 10px;
	border: solid 2px white;
	border-radius: 50%;
}

.three-i-principle-content-point {
	padding-left: 2vw;
	padding-right: 2vw;
}


.three-i-principle-content-detail {
	margin-left: 20px;
}

.three-i-principle-content-detail ul {
	list-style: none;
	margin: 0em;
	padding: 0;
  }

.three-i-principle-content-detail  ul li {
	margin: 0;
	margin-bottom: 0.6em;
	padding-left: 1.5em;
	position: relative;
  }

.three-i-principle-content-detail ul li:before {
	content: '';
	height: .4em;
	width: .4em;
	background: white;
	display: block;
	position: absolute;
	transform: rotate(45deg);
	top: .6em;
	left: 0;
 }
  
.three-i-principle-content-point-header {
	display: inline-block;
}

.three-i-principle-content-point-header::after{
	content: '';
	display: block;
	border-top: 2px solid white;
	margin-top: 0.5em;
}



.faq {
	margin-bottom: 5vw;
	padding-left: 7.5%;
	padding-right: 7.5%;
}

.faq-header {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-top: -50px;
	margin-bottom: 2vw;
}

.faq-header::after {
	width: 10%;
	content: '';
	border-top: 2px solid black;
	margin-top: 0.5em;
	margin-left: 45%;
}

.accordion {
	background-color: #eee;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	transition: 0.4s;
}

.curr, .accordion:hover {
	background-color: #ccc;
}

.accordion:after {
	content: '\002B';
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
}

.curr:after {
	content: "\2212";
}

.panel {
	padding: 0 18px;
	background-color: white;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}


.panel ul li {
	margin-bottom: 10px;
}



/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) {  
	.three-i-principle-content {
		width: 85%;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  

	.three-i-principle-content {
		width: 80%;
	}
}


/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

	.three-i-principle-content {
		width: 70%;
	}


	.three-i-principle-content-point {
		padding-left: 5vw;
		padding-right: 5vw;
	}

}

@media (min-width: 1200px) {

	.three-i-principle-content-point {
		padding-left: 8vw;
		padding-right: 8vw;
	}

    
}

@media (min-width: 1400px) {

	.three-i-principle-content-point {
		padding-left: 12vw;
		padding-right: 12vw;
	}

}