.form-header {
	background-image: url("https://satprerna.org/satprerna_backend/images/decoration/pmt-cover-bg.png");
	background-size: contain;
	background-repeat: repeat;
	background-position: bottom;
}

.form-header-volunteer {
	background-image: url("https://satprerna.org/satprerna_backend/images/decoration/SutraBackground.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}

.form-wrapper {
	position: relative;
	background-color: white;
	max-width: 1400px;
	margin-left: 20px;
	margin-right: 20px;
	top: -17.5vw;
	padding: 60px 20px 60px 20px;
	-webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); 
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);	
	border-radius: 25px;
}

.form-wrapper2 {
	position: relative;
	background-color: white;
	padding-left: 5%;
	padding-right: 5%;
}

.dsr-form-header {
	display: flex;
	flex-direction: column;
}

.aa {
	width: 300px;
	border: 8px solid white;
	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2); 
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
	border-radius: 2px;
	box-sizing: content-box;
}

.t1 {
	display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'lato';
    color: #9a0808;
    font-size: 2rem;
}

.t1::after {
    content: '';
    border-top: 3px solid #9a0808;
    margin-top: 15px;
    width: 100px;
}

.t2 {
	display: flex;
    flex-direction: column;
    position: relative;
    color: grey;
    font-size: 1.2rem;
    font-weight: 500;
}

.pmt-form-buttons {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.pmt-submit-btn {
	margin-top: 10px;
}

@media (min-width: 400px) {
	.pmt-form-buttons {
		flex-direction: row;
	}

	.pmt-submit-btn {
		margin-top: 0px;
	}

	.dsr-form-header {
		flex-direction: row;
		justify-content: space-between;
	}
}


@media (min-width: 768px) {
	.form-wrapper {
		margin-left: auto;
		margin-right: auto;
		padding: 60px 80px 60px 80px;
		border-radius: 5px;
	}
}

@media (min-width: 1200px) {
	.form-wrapper2 {
		padding-left: 7.5%;
		padding-right: 7.5%;
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;
	}
}
