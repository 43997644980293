.contactus-wrapper {
	background-color: white;
	max-width: 1200px;
	padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 5%;
    background-color: white;
}

.contactus-content-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: center;
	margin-top: 50px;
}

.contactus-addr-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 600px;
	max-width: 450px;
}

.contactus-social-media a {
	color: #333;
}

.contactus-form-wrapper{
	max-width: 450px;
	width: 100%;
}

.contactus-maps {
	width: 100%;
	height: 400px;
}

.contactus-backdrop {
	z-index: 1000;
	color: white;
}

@media (min-width: 992px) {
	.collaborate-content-wrapper {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }
	.contactus-content-wrapper {
		margin-left: auto;
		margin-right: auto;
		flex-direction: row;
		margin-top: 100px;
	}
}