.login-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.login-wrapper-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-header {
    margin-top: 20px;
}