:root {
  --color-primary: #6B7A8F;
  --color-secondary: #101118;
  --color-accent: #1D1F2F;
  --color-focus: #6D64F7;
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}

.suvakya-wrapper-outer {
  padding-left: 7.5%;
  padding-right: 7.5%;
  margin-bottom: 100px;
}

.suvakya-wrapper {
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  overflow-x: hidden;
  overflow-y: visible;
  width: 100%;
  height: 90vmin;
}

.suvakya-visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.suvakya-slider-icon {
  fill: #333;
  width: 100%;
}

.suvakya-btn {
  background-color: #333;
  border: none;
  border-radius: 0.125rem;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2.5rem 1.125rem;
}

.suvakya-btn:focus {
  outline-color: #333;
  outline-offset: 2px;
  outline-style: solid;
  outline-width: 3px;
}

.suvakya-btn:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.suvakya-slider__controls {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: space-between;
          justify-content: space-between;
  align-items: center;
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
}

.suvakya-slide__download {
  outline: none;
  border-style: none;
  background-color: white;
  transition: transform 0.1s ease-in-out;
}

.suvakya-slide__download:hover, .suvakya-slide__download:focus {
  outline: none;
  border-style: none;
  background-color: white;
}

.suvakya-slide__download:active {
  outline: none;
  border-style: none;
  background-color: white;
  -webkit-transform: translateY(5%);
          transform: translateY(5%);
}

.suvakya-slider__controls .suvakya-btn {
  --size: 3rem;
  -webkit-box-align: center;
          align-items: center;
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 100%;
  display: -webkit-box;
  display: flex;
  height: var(--size);
  padding: 0;
  width: var(--size);
}

.suvakya-slider__controls .suvakya-btn:focus {
  border-color: #333;
  outline: none;
}

.suvakya-slider__controls .suvakya-btn--previous > * {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.suvakya-slider {
  --slide-size: 70vmin;
  --slide-margin: 4vmin;
  height: var(--slide-size);
  margin: 0 auto;
  position: relative;
  width: var(--slide-size);
}

.suvakya-slider__wrapper {
  display: -webkit-box;
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  padding: 0;
  -webkit-transition: -webkit-transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
  transition: -webkit-transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1), -webkit-transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

.suvakya-slide {
  -webkit-box-align: center;
          align-items: center;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: var(--slide-size);
  -webkit-box-pack: center;
          justify-content: center;
  margin: 0 var(--slide-margin);
  opacity: 0.25;
  position: relative;
  text-align: center;
  -webkit-transition: opacity calc(var(--base-duration) / 2) var(--base-ease), -webkit-transform calc(var(--base-duration) / 2) var(--base-ease);
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease), -webkit-transform calc(var(--base-duration) / 2) var(--base-ease);
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease);
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease), -webkit-transform calc(var(--base-duration) / 2) var(--base-ease);
  width: var(--slide-size);
  z-index: 1;
}

.suvakya-slide--previous:hover, .suvakya-slide--next:hover {
  opacity: 0.5;
}

.suvakya-slide--previous {
  cursor: w-resize;
}

.suvakya-slide--previous:hover {
  -webkit-transform: translateX(2%);
          transform: translateX(2%);
}

.suvakya-slide--next {
  cursor: e-resize;
}

.suvakya-slide--next:hover {
  -webkit-transform: translateX(-2%);
          transform: translateX(-2%);
}

.suvakya-slide--current {
  --x: 0;
  --y: 0;
  --d: 50;
  opacity: 1;
  pointer-events: auto;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

@media (hover: hover) {
  .suvakya-slide--current:hover .suvakya-slide__image-wrapper {
    -webkit-transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
            transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
  }
}

.suvakya-slide__image-wrapper {
  background-color: #eee;
  border-radius: 1%;
  height: 100%;
  left: 0%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  -webkit-transition: -webkit-transform calc(var(--base-duration) / 4) var(--base-ease);
  transition: -webkit-transform calc(var(--base-duration) / 4) var(--base-ease);
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  transition: transform calc(var(--base-duration) / 4) var(--base-ease), -webkit-transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 100%;
}

.suvakya-slide__image {
  --d: 20;
  height: 100%;
  left: 0%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0%;
  -webkit-transition: opacity var(--base-duration) var(--base-ease), -webkit-transform var(--base-duration) var(--base-ease);
  transition: opacity var(--base-duration) var(--base-ease), -webkit-transform var(--base-duration) var(--base-ease);
  transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease);
  transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease), -webkit-transform var(--base-duration) var(--base-ease);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}
@media (hover: hover) {
  .suvakya-slide--current .suvakya-slide__image {
    -webkit-transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
            transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
  }
}


@media (min-width: 768px) {
  .suvakya-slider {
  --slide-size: 50vmin;
  --slide-margin: 4vmin;
  }
  .suvakya-wrapper {
    height: 70vmin;
  }
}

@media (min-width: 1200px) {
  .suvakya-wrapper-outer {
    margin-bottom: 0;
  }
}
