@import "~react-image-gallery/styles/css/image-gallery.css";

.gallery-header {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'Pacifico';
    font-weight: 400;
    color: #9a0808;
    text-align: center;
    margin-top: 30px;
    font-size: calc(15px + 1rem);
    line-height: 50px;
}

.gallery-header::after {
    content: '';
    border-top: 5px solid #9a0808;
    margin-top: 15px;
    width: 25%;
    margin-left: 37.5%;
}

.gallery-header-alt {
	color: white;
}

.gallery-header-alt::after {
	border-top: 5px solid white;
}

.gallery-alt-bg {
	background-image: url("https://satprerna.org/satprerna_backend/images/dsr/back1.jpg");
	background-size: contain;
    background-repeat: repeat;
	background-position: right top;
	height: auto;
}

.gallery-wrapper {
	display: flex;
	flex-direction: column;
	align-content: center;
	background-image: url("https://satprerna.org/satprerna_backend/images/decoration/LotusFlower.png");
	background-size: auto auto;
    background-repeat: repeat;
    background-attachment: local;
    overflow: hidden;
}

.gallery-main-header {
	display: flex;
    flex-direction: column;
	position: absolute;
	top: calc(100px + 4vw);
	left: 5%;
	font-family: 'Pacifico';
	font-weight: 400;
    color: white;
    text-align: center;
    font-size: calc(25px + 1vw);
}

.gallery-main-header::after {
    content: '';
    border-top: 5px solid white;
    margin-top: 3%;
    width: 30%;
    margin-left: 35%;
}

.gallery-inner {
	
}

.gallery-contents {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.gallery-contents-rev {
    display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
}

.gallery-text {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 0%;
	padding: 10px;
	border: 5px solid #9a0808;
	width: 100%;
	max-width: 450px;
	max-height: 400px;
	height: auto;
	margin-bottom: 50px;
	-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.5); 
	box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
}

.gallery-text p b {
	color: #9a0808;
}

.gallery-slider {
	width: 90%;
	box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
	background-color: rgba(0,0,0,0.6);
}

.fullscreen .image-gallery-slide img {
    height: 100vh;
    width: 100%;
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 576px) {  
	.gallery-main-header {
		left: 20%;
	}

	.image-gallery-slide img {
	    width: 100%;
	    height: 300px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
	.gallery-text {
		padding: 30px;
		width: 60%;
	}
	.gallery-main-header {
		left: 25%;
	}
	.image-gallery-slide img {
	    height: 600px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
	.gallery-contents {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.gallery-contents-rev {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.gallery-text {
		width: 50%;
		margin-bottom: 0;
	}
	.gallery-main-header {
		left: 30%;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
	.gallery-text {
		width: 40%;
		height: 400px;
	}
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1600px) {  
	.gallery-main-header {
		left: 35%;
		top: 200px;
	}
}